import AboutImage from '../../assets/pounded yam.jpg'
// import CV from '../../assets/cv.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About" />
                </div>
            </div>
            <div className="about__right">
                <h2>About Us</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                Our resaurant offers a wide variety of meals that delivers an original taste of homemade meals while prioriting health hygiene.
                </p>
                <p>
                We have a great selection of quality food and premium drinks, best in efficiency, profitability and with superior customer service experience. Customer satisfaction is our paramount objective.
                </p>
                {/* <a href="" className='btn primary'>Download CV <HiDownload/></a> */}
            </div>
        </div>
    </section>
  )
}

export default About