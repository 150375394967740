import Image1 from '../../assets/dodo.jpg'
import Image2 from '../../assets/egusi.jpg'
import Image3 from '../../assets/exotic soup.jpg'
import Image4 from '../../assets/fried rice.jpg'
import Image5 from '../../assets/fried turkey.jpg'
import Image6 from '../../assets/B.jpg'
import Image7 from '../../assets/pounded yam.jpg'
import Image8 from '../../assets/ricebeans.jpg'
import Image9 from '../../assets/seasoned meat.jpg'
import Image10 from '../../assets/assorted.jpg'
import Image11 from '../../assets/chips and chicken.jpg'
import Image12 from '../../assets/cocnut rice.jpg'
import Image13 from '../../assets/okro.jpg'
import Image14 from '../../assets/semo lala.jpg'
import Image15 from '../../assets/jollof.jpg'

const data = [
    {
        id: 1,
        category: 'Fries',
        image: Image1,
        title: "Crunchy Plantain",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 2,
        category: 'Soup',
        image: Image2,
        title: "Egusi Soup",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 3,
        category: 'Soup',
        image: Image3,
        title: "Ewedu and Gbegiri",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 4,
        category: 'Rice Meal',
        image: Image4,
        title: "Fried Rice",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 5,
        category: 'Fries',
        image: Image5,
        title: "Proteinous Turkey",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! ",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 6,
        category: 'Delicacies',
        image: Image6,
        title: "Yam Porridge",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 7,
        category: 'Traditional',
        image: Image7,
        title: "Pounded Yam",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 8,
        category: 'Rice Meal',
        image: Image8,
        title: "Rice and Beans",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 9,
        category: 'Fries',
        image: Image9,
        title: "Seasoned Meat",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 10,
        category: 'Delicacies',
        image: Image10,
        title: "Assorted Meat",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 11,
        category: 'Fries',
        image: Image11,
        title: "Chips & Chicken",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 12,
        category: 'Rice Meal',
        image: Image12,
        title: "Coconut Rice",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 14,
        category: 'Traditional',
        image: Image14,
        title: "Semo Meal",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 15,
        category: 'Rice Meal',
        image: Image15,
        title: "Jollof Rice",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    },
    {
        id: 13,
        category: 'Soup',
        image: Image13,
        title: "Okro Soup",
        desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/egattor'
    }
]


export default data