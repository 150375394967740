import Carousell from "./sections/carousel/Carousell";
import "./index.css";
import About from "./sections/about/About";
import Contact from "./sections/contact/Contact";
// import FAQ from "./sections/faq/FAQ";
import FloatingNav from "./sections/floating-nav/FloatingNav";
import Footer from "./sections/footer/Footer";
import Header from "./sections/header/Header";
import Navbar from "./sections/navbar/Navbar";
import Portfolio from "./sections/portfolio/Portfolio";
import Services from "./sections/services/Services";
// import Testimonials from "./sections/testimonials/Testimonials";

const App = () => {
  return (
    <main>
      <Navbar />
      <Carousell />
      <Header />
      <About />
      <Services />
      <Portfolio />
      {/* <Testimonials />
      <FAQ /> */}
      <Contact />
      <Footer />
      <FloatingNav />
    </main>
  );
};

export default App;
