import { IoMdRestaurant } from "react-icons/io";
import { GrRestaurant } from "react-icons/gr";
import { MdTableRestaurant } from "react-icons/md";
import { BiRestaurant } from "react-icons/bi";


const data = [
    {
        id: 1, icon: <IoMdRestaurant/>, title: 'Buffet Services', desc: "We offer Buffet Services that is second-no-none. Our clients and their guests can choose from several meals that cater for a wide variety of tasyes and preferences."
    },
    {
        id: 2, icon: <GrRestaurant />, title: 'Lounge Services', desc: "We offer our client a relaxed and sophisticated form of services where they can choose from a menu that typically includes a variety of finger foods, small plates and beverages."
    },
    {
        id: 3, icon: <MdTableRestaurant />, title: 'French Services', desc: "Our waiters are well-trained to present to the clients different cuisines from which they can choose from. We are professionals at this."
    },
    {
        id: 4, icon: <BiRestaurant />, title: 'American Services', desc: 'From the kitchen to the table; From the Chef to the Diner, Our Meals are well=prepared and arranged according to standards as enjoyment is of utmost concern in our business. We offer American Services for both Traditional and Foreign Meals.'
    }
]


export default data