import { useEffect } from "react";
import data from "./data";
// import AOS from 'aos'
// import 'aos/dist/aos.css'
import "./header.css";

const Header = () => {
  useEffect(() => {
    // AOS.init({duration: 2000})
  }, []);

  return (
    <header id="header">
      <div className="container header__container">
        <h3 data-aos="fade-up">RingsRoss Restaurant</h3>
        <p data-aos="fade-up">
          You are just a click away to have your favourite exquisite cuisine.
          We offer your meal in a balanced manner that is pocket-friendly/
          Your nasal and taste buds will nourish your soul to maximal healthy esteem as you try us.
        </p>
        <div className="header__cta" data-aos="fade-up">
          <a href="#contact" className="btn primary">
            Contact Us
          </a>
          <a href="#portfolio" className="btn light">
            Check Our Gallery
          </a>
        </div>
        <div className="header__socials">
          {data.map((item) => (
            <a
              key={item.id}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}
            </a>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Header;
