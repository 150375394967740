import contacts from "./data";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { FaPhoneVolume } from "react-icons/fa6";
import "./contact.css";

const Contact = () => {
  return (
    <section id="contact">
      <h2>Get In Touch</h2>
      <p>
        <b>
          <span className="icon">
            <HiBuildingOffice2 />
          </span>{" "}
          Unit 102, Onyearugbulem Shopping Plaza, Akure-Owo Expressway, Akure
          Ondo State
          <br />{" "}
          <span className="icon">
            <FaPhoneVolume />
          </span>{" "}
          08103275094
        </b>
      </p>
      <div className="container contact__container" data-aos="fade-up">
        {contacts.map((contact) => (
          <a
            key={contact.id}
            href={contact.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contact.icon}
          </a>
        ))}
      </div>
    </section>
  );
};

export default Contact;
