import {AiOutlineInstagram} from 'react-icons/ai'
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

export const links = [
    {id: 1, link: '#', title: 'Home'},
    {id: 2, link: '#about', title: 'About'},
    {id: 3, link: '#services', title: 'Services'},
    {id: 4, link: '#portfolio', title: 'Meals & Menu'},
    {id: 5, link: '#contact', title: 'Contact'}
]


export const socials = [
    {id: 1, link: 'https://instagram.com', icon: <AiOutlineInstagram/>},
    {id: 2, link: 'https://twitter.com', icon: <FaXTwitter />},
    {id: 3, link: 'https://youtube.com', icon: <FaYoutube />},
    {id: 4, link: 'https://linkedin.com', icon: <FaLinkedin />}
]