import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./carousel.css";

const imageData = [
  {
    label: "The Choicest Restaurant for Every Family",
    alt: "image1",
    url: require("../../assets/img2.jpg"),
  },
  {
    label: "Our Environment Gives Your Meal the Desired Satisfaction",
    alt: "image1",
    url: require("../../assets/img3.jpg"),
  },
  {
    label:
      "We offer you Services and make you feel at Home Anytime, Any Day, ...",
    alt: "image1",
    url: require("../../assets/img4.jpg"),
  },
  {
    label: "We offer Nothing But the Best",
    alt: "image1",
    url: require("../../assets/img5.jpg"),
  },
  {
    label: "Carefully Prepared Dishes that appeal to Taste Budss",
    alt: "image1",
    url: require("../../assets/img6.jpg"),
  },
  {
    label: "Your Comfort and Serenity means a lot to us",
    alt: "image1",
    url: require("../../assets/img8.jpg"),
  },
  {
    label: "Yummy, Yummy, All the Way",
    alt: "image1",
    url: require("../../assets/img9.jpg"),
  },
];

const renderSlides = imageData.map((image) => (
  <div key={image.alt}>
    <img src={image.url} alt={image.alt} />
    <p className="legend">{image.label}</p>
  </div>
));

const Carousell = () => {
  const [currentIndex, setCurrentIndex] = useState();
  function handleChange(index) {
    setCurrentIndex(index);
  }
  return (
    <section id="carous">
      <div className="Carous">
        <Carousel
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          // selectedItem={imageData[currentIndex]}
          onChange={handleChange}
          className="carousel-container"
        >
          {renderSlides}
        </Carousel>
      </div>
    </section>
  );
};

export default Carousell;
