import {AiOutlineInstagram} from 'react-icons/ai'
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const data = [
    {id: 1, link: 'https://instagram.com', icon: <AiOutlineInstagram/>},
    {id: 2, link: 'https://twitter.com', icon: <FaXTwitter />},
    {id: 3, link: 'https://youtube.com', icon: <FaYoutube />},
    {id: 4, link: 'https://linkedin.com', icon: <FaLinkedin />}
]

export default data